
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ApiAuth } from "@/core/api";
import { getUserRoute, getUserRouteType } from "@/core/directive/function/user";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "user-detail",
  components: {},
  setup() {
    const route = useRoute();
    const { t } = useI18n();

    const formData = ref({
      username: "",
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      mobile: "",
      telephone: "",
      address: "",
      postcode: "",
      city: "",
      country_iso_2: "",
      language: "en_US",
    });

    const getUserData = () => {
      ApiAuth.getUserInfo({ id: route.params.id })
        .then(({ data }) => {
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      getUserData();
    });

    return {
      t,
      formData,
      getUserRouteType,
      getUserRoute,
    };
  },
});
