/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-30 13:52:18
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-30 14:00:21
 * @FilePath: /base-frontend/src/core/directive/function/user.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export const getUserRouteType = computed(() => {
  return (route) => {
    const name = route.name as string;
    return name.match("user-management-system-users");
  };
});

export const getUserRoute = computed(() => {
  return (route) => {
    if (getUserRouteType.value(route)) {
      return "/user-management/system-users/";
    }
    return "/user-management/saas-users/";
  };
});

export const getUserRouteDetailBCN = (route) => {
  const { t } = useI18n();
  /* eslint-disable prefer-const */
  let routes: any[] = [],
    currentName = "",
    path = "";
  const routeName = route.name as string;
  routes.push({
    name: t("user.userManagement"),
    path: "",
  });
  if (routeName.endsWith("-users")) {
    if (getUserRouteType.value(route)) {
      currentName = t("user.systemUsers");
    } else {
      currentName = t("user.saasUsers");
    }
  } else {
    if (getUserRouteType.value(route)) {
      path = "/user-management/system-users";
      routes.push({
        name: t("user.systemUsers"),
        path: path,
      });
    } else {
      path = "/user-management/saas-users";
      routes.push({
        name: t("user.saasUsers"),
        path: path,
      });
    }
    if (routeName.match("-overview")) {
      currentName = t("user.overview");
    } else if (routeName.match("-settings")) {
      currentName = t("user.settings");
    } else if (routeName.match("-permission")) {
      currentName = t("user.permission");
    } else if (routeName.match("-managed-customers")) {
      currentName = t("user.managedCustomers");
    } else if (routeName.match("-managed-merchants")) {
      currentName = t("user.managedMerchants");
    }
  }

  setCurrentPageBreadcrumbs(currentName, routes);
};
